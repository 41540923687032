import Vue from 'vue'
import Element from 'element-ui';
// Element.Dialog.props.lockScroll.default = false;

import App from './App.vue'
Vue.use(Element, { size: 'small', zIndex: 3000 });

import router from './router'

import animate from 'animate.css'
// Vue.use(animate)

import $ from 'jquery'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

// 滚动到顶部
import scrollToTopMixin from './utils/scrollToTopMixin';
Vue.mixin(scrollToTopMixin);

// import WechatPlugin from './utils/wechatPlugin'
// 全局注册微信jsdk
// Vue.use(WechatPlugin)

// Vue.config.ignoredElements = ['wx-open-launch-weapp']

Vue.prototype.$ = $

import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: '3qa3NTq60sobqVwo3ZV5nyksYSouxZAn'
})

import "./assets/js/main/jquery.appear.js"
import "./assets/js/main/parallax.min.js"
import "./assets/js/main/isotope.pkgd.min.js"
import "./assets/js/main/owl.carousel.min.js"
import "./assets/js/main/main.js"

import "./assets/css/owl-carousel/owl.transitions.css"
import "./assets/css/owl-carousel/owl.theme.css"
import "./assets/css/owl-carousel/owl.carousel.css"
import "./assets/css/main/main.css";

import "./assets/css/font-awesome.css"

import "./assets/css/style.css";


import "./assets/css/common.scss"

import api from "@/api/index"; // 导入api接口
Vue.prototype.$api = api;

import util from '@/utils/utils'
Vue.prototype.$util = util

import * as filters from './utils/filter.js'
// document.cookie="www-cookies="+new Date();
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
import to from './utils/to';

Vue.use(to);
document.cookie="www-cookies="+new Date();
// import * as echarts from 'echarts'
// Vue.prototype.$echarts = echarts

import store from './store';

Vue.config.productionTip = false

// new Vue({
//   router,
//   render: h => h(App)
// }).$mount('#app')

new Vue({
  el: '#app',
  router,
  store,
  render: h =>h(App)
}).$mount("#app")
