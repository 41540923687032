// scrollToTopMixin.js
export default {
    mounted() {
        this.scrollToTop();
    },
    methods: {
        scrollToTop() {
            const listContainer = this.$refs.container;
            if (listContainer) {
                const listContainerPosition = listContainer.getBoundingClientRect().top;
                const targetPosition = window.pageYOffset + listContainerPosition;
                const step = Math.abs(window.pageYOffset - targetPosition) / 40;

                const scrollTo = (position) => {
                    window.scrollTo({
                        top: position,
                        behavior: 'smooth' // 平滑滚动效果
                    });
                };

                if (window.pageYOffset > targetPosition) {
                    let currentPosition = window.pageYOffset;
                    const scrollUp = () => {
                        currentPosition -= step;
                        if (currentPosition > targetPosition) {
                            scrollTo(currentPosition);
                            requestAnimationFrame(scrollUp);
                        } else {
                            scrollTo(targetPosition);
                        }
                    };
                    scrollUp();
                } else {
                    let currentPosition = window.pageYOffset;
                    const scrollDown = () => {
                        currentPosition += step;
                        if (currentPosition < targetPosition) {
                            scrollTo(currentPosition);
                            requestAnimationFrame(scrollDown);
                        } else {
                            scrollTo(targetPosition);
                        }
                    };
                    scrollDown();
                }
            }
        }
    },
};
