<template>
  <div id="client" class="pb50">
    <div class="container">
      <div class="row text-center mb25">
        <h1 class="font-size-normal">友情链接<small class="heading heading-solid center-block"></small> </h1>
      </div>
      <div class="row">
        <!-- logo client start -->
        <div class="col-md-2 col-sm-3 col-xs-4" v-for="(item, index) in linksList" :key="index" @click="toLinks(item.url)">
          <img :src="item.logo" alt="client logo" class="img-responsive center-block animated fadeIn visible" data-animation="fadeIn" data-animation-delay="300">
        </div>

<!--        <div class="col-md-2 col-sm-3 col-xs-4"> <img src="../../assets/img/brand/logo01.jpg" alt="client logo" class="img-responsive center-block animated fadeIn visible" data-animation="fadeIn" data-animation-delay="300"> </div>-->
<!--        &lt;!&ndash; logo client end &ndash;&gt;-->
<!--        &lt;!&ndash; logo client start &ndash;&gt;-->
<!--        <div class="col-md-2 col-sm-3 col-xs-4"> <img src="../../assets/img/brand/logo02.jpg" alt="client logo" class="img-responsive center-block animated fadeIn visible" data-animation="fadeIn" data-animation-delay="150"> </div>-->
<!--        &lt;!&ndash; logo client end &ndash;&gt;-->
<!--        &lt;!&ndash; logo client start &ndash;&gt;-->
<!--        <div class="col-md-2 col-sm-3 col-xs-4"> <img src="../../assets/img/brand/logo03.jpg" alt="client logo" class="img-responsive center-block animated fadeIn visible" data-animation="fadeIn" data-animation-delay="100"> </div>-->
<!--        &lt;!&ndash; logo client end &ndash;&gt;-->
<!--        &lt;!&ndash; logo client start &ndash;&gt;-->
<!--        <div class="col-md-2 col-sm-3 col-xs-4"> <img src="../../assets/img/brand/logo04.jpg" alt="client logo" class="img-responsive center-block animated fadeIn visible" data-animation="fadeIn" data-animation-delay="150"> </div>-->
<!--        &lt;!&ndash; logo client end &ndash;&gt;-->
<!--        &lt;!&ndash; logo client start &ndash;&gt;-->
<!--        <div class="col-md-2 col-sm-3 col-xs-4"> <img src="../../assets/img/brand/logo05.jpg" alt="client logo" class="img-responsive center-block animated fadeIn visible" data-animation="fadeIn" data-animation-delay="200"> </div>-->
<!--        &lt;!&ndash; logo client end &ndash;&gt;-->
<!--        &lt;!&ndash; logo client start &ndash;&gt;-->
<!--        <div class="col-md-2 col-sm-3 col-xs-4"> <img src="../../assets/img/brand/logo06.jpg" alt="client logo" class="img-responsive center-block animated fadeIn visible" data-animation="fadeIn" data-animation-delay="300"> </div>-->
<!--        &lt;!&ndash; logo client end &ndash;&gt;-->
<!--        &lt;!&ndash; logo client start &ndash;&gt;-->
<!--        <div class="col-md-2 col-sm-3 col-xs-4"> <img src="../../assets/img/brand/logo07.jpg" alt="client logo" class="img-responsive center-block animated fadeIn visible" data-animation="fadeIn" data-animation-delay="300"> </div>-->
<!--        &lt;!&ndash; logo client end &ndash;&gt;-->
<!--        &lt;!&ndash; logo client start &ndash;&gt;-->
<!--        <div class="col-md-2 col-sm-3 col-xs-4"> <img src="../../assets/img/brand/logo08.jpg" alt="client logo" class="img-responsive center-block animated fadeIn visible" data-animation="fadeIn" data-animation-delay="150"> </div>-->
<!--        &lt;!&ndash; logo client end &ndash;&gt;-->
<!--        &lt;!&ndash; logo client start &ndash;&gt;-->
<!--        <div class="col-md-2 col-sm-3 col-xs-4"> <img src="../../assets/img/brand/logo09.jpg" alt="client logo" class="img-responsive center-block animated fadeIn visible" data-animation="fadeIn" data-animation-delay="100"> </div>-->
<!--        &lt;!&ndash; logo client end &ndash;&gt;-->
<!--        &lt;!&ndash; logo client start &ndash;&gt;-->
<!--        <div class="col-md-2 col-sm-3 col-xs-4"> <img src="../../assets/img/brand/logo10.jpg" alt="client logo" class="img-responsive center-block animated fadeIn visible" data-animation="fadeIn" data-animation-delay="150"> </div>-->
<!--        &lt;!&ndash; logo client end &ndash;&gt;-->
<!--        &lt;!&ndash; logo client start &ndash;&gt;-->
<!--        <div class="col-md-2 col-sm-3 col-xs-4"> <img src="../../assets/img/brand/logo11.jpg" alt="client logo" class="img-responsive center-block animated fadeIn visible" data-animation="fadeIn" data-animation-delay="200"> </div>-->
<!--        &lt;!&ndash; logo client end &ndash;&gt;-->
<!--        &lt;!&ndash; logo client start &ndash;&gt;-->
<!--        <div class="col-md-2 col-sm-3 col-xs-4"> <img src="../../assets/img/brand/logo12.jpg" alt="client logo" class="img-responsive center-block animated fadeIn visible" data-animation="fadeIn" data-animation-delay="300"> </div>-->
        <!-- logo client end -->
      </div>
      <!-- row end -->
    </div>
    <!-- container end -->
  </div>
</template>

<script>
export default {
  name: "FriendlyLink",
  data() {
    return {
      linksList: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getUcLinksList()
    },
    getUcLinksList() {
      this.$api.www.getUcLinksList({}).then(res=>{
        this.linksList = res.data
      })
    },
    regHttp(v) {
      if ( v.substr(0, 7).toLowerCase() == 'http://' ||v.substr(0, 8).toLowerCase() == 'https://' ) {
        return v
      } else {
        return 'http://' + v
      }
    },
    toLinks(val){
      window.open(this.regHttp(val),"_blank")
    }
  }
}
</script>

<style scoped>

</style>