import axios from 'axios'
import router from '../router'
// import store from '@/store'
import {getToken, removeToken, setToken} from '@/utils/auth'
import Cookies from 'js-cookie'

const DOMPurify = require('dompurify');

// create an axios instance
const service = axios.create({
    baseURL: 'https://gateway.wlmqgtzs.com', // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 600000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent
        // if (getToken()) {
        //     config.headers['ecare-platform-token'] = getToken()
        // }
        document.cookie="www-cookies="+new Date();

        if (config.data && typeof config.data === 'object') {
            for (const key in config.data) {
                if (typeof config.data[key] === 'string') {
                    config.data[key] = DOMPurify.sanitize(config.data[key]);
                }
            }
        }
        console.log("data", config.data)
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        console.log(response)
        if (response.headers['ecare-platform-token'] != undefined) {
            setToken(response.headers['ecare-platform-token'])
        }
        const res = response.data
        // if the custom code is not 20000, it is judged as an error.
        if (res.code !== 0) {
            if (res.code == 401) {
                removeToken();
                router.push(`/login?redirect=` + router.currentRoute.fullPath)
            } else {
                return Promise.reject(res)
            }
        } else {
            return res
        }
    },
    error => {
        console.log(error.response)
        if (error.response.status == 401) {
            return Promise.reject({code: 401, data: null, msg: "暂无权限"})
        } else {
            // Message({
            //     message: error.message,
            //     type: 'error',
            //     duration: 5 * 1000
            // })
            return Promise.reject(error)
        }
    }
)

export default service