export default{
    isWx(ua) {
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            wx.miniProgram.getEnv((res) => {
                if (res.miniprogram) {
                    return true
                } else {
                    return false
                }
            });
        } else {
            return false
        }
    },
}