<template>
  <div>
    <div class="plug_service am-animation-slide-bottom">
      <ul>
        <li @click="to">
          <a>
            <img style="width: 35px;height: 35px" src="https://railway-park.oss-cn-shanghai.aliyuncs.com/business/broker/2023/07/1690029781117.svg" />
            <div>热销产品</div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['getWebsiteInfo']),
  },
  name: "index",
  data() {
    return {

    }
  },
  methods: {
    to() {
      // 判断当前环境是否为小程序
      // const ua = navigator.userAgent.toLowerCase();
      // if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      //   wx.miniProgram.getEnv((res) => {
      //     if (res.miniprogram) {
      //       uni.navigateTo({
      //         url: '/pagesEcharts/chart/list'
      //       });
      //     } else {
      //       this.toPc()
      //     }
      //   });
      // } else {
      //   this.toPc()
      // }
      this.$customNavigate('/chart', '/pagesEcharts/chart/list');
    },
    toPc() {
      // this.$customNavigate('/chart');
      // // 获取当前页面的路径
      // const currentPath = this.$route.path;
      // // 判断当前页面是否已经是 "/chart"
      // if (currentPath !== '/chart') {
      //   // 如果当前页面不是 "/chart/info"，则进行跳转
      //   this.$router.push('/chart');
      // }
    }
  }
}
</script>

<style scoped>



.plug_service {
  border-radius: 10px;
  position: fixed;
  left: 1%;
  bottom: 2%;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.7);
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 68px;
}
.plug_service li a {
  cursor: pointer;
  display: block;
  text-align: center;
  padding: 2px 8px 6px 8px;
  font-size: 12px;
  color: #505050;
  outline: none;
}
.plug_service div {
  font-size: 10px;
}
.am-animation-slide-bottom {
  -webkit-animation-name: am-slide-bottom;
  animation-name: am-slide-bottom;
}
/* 隐藏内容容器 */
.content-container-gfwx {
  display: none;
  border-radius: 10px;
  position: absolute;
  left: 100%;
  top: 0;
  margin-left: -195px;
  padding: 10px;
  background-color: #f1f1f1;
  border: 1px solid #ececec;
}

/* 当鼠标悬停在列表项上时，显示内容容器 */
li:hover .content-container-gfwx {
  display: block;
}
/* 隐藏内容容器 */
.content-container-kfrx {
  display: none;
  border-radius: 10px;
  position: absolute;
  left: 100%;
  top: 0;
  margin-left: -220px;
  margin-top: 65px;
  padding: 10px;
  background-color: #f1f1f1;
  border: 1px solid #ececec;
}

/* 当鼠标悬停在列表项上时，显示内容容器 */
li:hover .content-container-kfrx {
  display: block;
}
</style>
