<template>
  <div id="app" ng-app="myApp" >
<!--    <keep-alive>-->
<!--      <router-view :key="$route.fullPath"></router-view>-->
<!--    </keep-alive>-->
    <router-view :key="$route.fullPath" />
    <BottomRight v-if="$route.path !== '/common/pdf'"/>
    <BottomLeft v-if="$route.path !== '/common/pdf'"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BottomRight from "@/components/bottom-right/index.vue";
import BottomLeft from "@/components/bottom-left/index.vue";
import Cookies from 'js-cookie'
  export default {
    components:{
      BottomRight,
      BottomLeft
    },
    computed: {
      ...mapGetters(['getWebsiteInfo']),
    },
    data() {
      return {
        level2: [
          {type: 'url', url: '/about?navId=9&about=adoutContent',navName: '关于我们', parentId: 9},
          {type: 'url', url: '/about?navId=9&about=committeeContent',navName: '联系我们', parentId: 9},
          {type: 'url', url: '/about?navId=9&about=4',navName: '地理位置', parentId: 9}
        ]
      }
    },
    created() {
      this.initializeWebsite();
    },
    methods: {
      initializeWebsite() {
        this.getUcAdout().then(() => {
          const aboutInfo = this.$store.getters.getAboutInfo;
          localStorage.setItem('aboutInfo', JSON.stringify(aboutInfo));
          console.log('About Info:', aboutInfo);
        });
        this.getUcWebsiteInfoVO().then(() => {
          const websiteInfo = this.$store.getters.getWebsiteInfo;
          console.log('Website Info:', websiteInfo);
        });
        this.getUcHomeNavigationList().then(() => {
          const navList = this.$store.getters.getNavList;
          console.log('Nav List:', navList);
        });
        localStorage.getItem('navId')
      },
      getUcAdout() {
        return this.$api.www.getUcAdout({}).then((res) => {
          this.$store.dispatch('setAboutInfo', res.data);
        });
      },
      getUcWebsiteInfoVO() {
        return this.$api.www.getUcWebsiteInfoVO({}).then((res) => {
          this.$store.dispatch('setWebsiteInfo', res.data);
        });
      },
      getUcHomeNavigationList() {
        return this.$api.www.getUcHomeNavigationList({}).then(res=>{
          res.data.level2 = res.data.level2.concat(this.level2);
          this.$store.dispatch('setNavList', res.data);
        })
      },
    }

  }
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}


</style>
