import dayjs from 'dayjs'

export function dateFormat(val){
    if (val!=null){
       let  date =dayjs(val).format('YYYY-MM-DD HH:mm:ss')
        if (date==='2099-01-01 00:00:00'){
            return "长期"
        }
        return date
    }else {
        return "暂无数据"
    }
}

export function clickInDateFormat(val){
    if (val!=null){
        let  date =dayjs(val).format('YYYY-MM-DD HH:mm')
        if (date==='2099-01-01 00:00:00'){
            return "长期"
        }
        return date
    }else {
        return "暂无数据"
    }
}

export function birthDateFormat(val){
    if (val!=null){
        return dayjs(val).format('YYYY-MM-DD')
    }else {
        return "未填写"
    }
}

export function sexType(value) {
    let sex = "";
    switch (value){
        case 0:
            sex = '男';
            break;
        case 1:
            sex = '女';
            break;
        default:
            sex = '未知';
            break;
    }
    return sex;
}

export function couponType(value) {
    let coupon = "";
    switch (value){
        case 0:
            coupon = '满减';
            break;
        case 1:
            coupon = '立减';
            break;
        default:
            coupon = '折扣';
            break;
    }
    return coupon;
}

export function activityStatus(value) {
    let state = "";
    switch (value){
        case 0:
            state = '启用';
            break;
        case 1:
            state = '未启用';
            break;
        default:
            state = '已过期';
            break;
    }
    return state;
}

export default {
    install(Vue) {
        Vue.filter('dateFormat', dateFormat)
        Vue.filter('birthDateFormat', birthDateFormat)
        Vue.filter('clickInDateFormat', clickInDateFormat)
        Vue.filter('sexType', sexType)
        Vue.filter('couponType', couponType)
        Vue.filter('activityStatus', activityStatus)
    }
}