import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import wechatIndex from '../views/wechat-index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component:index
  },
  {
    path: '/index',
    name: 'index',
    component:index
  },
  {
    path: '/common/pdf',
    name: 'common/pdf',
    component: () => import(/* webpackChunkName: "about" */ '../views/common/pdf/index.vue')
  },
  {
    path: '/wechat-index',
    name: 'wechat-index',
    component: wechatIndex
  },
  {
    path: '/news',
    name: 'news',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/news/index.vue')
  },
  {
    path: '/news/info',
    name: 'news/info',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/news/info.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/about/index.vue')
  },
  {
    path: '/serve',
    name: 'serve',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/serve/index.vue')
  },
  {
    path: '/serve/info',
    name: 'serve/info',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/serve/info.vue')
  },
  {
    path: '/cbd',
    name: 'cbd',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/cbd/index.vue')
  },
  {
    path: '/cbd/info',
    name: 'cbd/info',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/cbd/info.vue')
  },
  {
    path: '/industrialPark',
    name: 'industrialPark',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/industrialPark/index.vue')
  },
  {
    path: '/industrialPark/info',
    name: 'industrialPark/info',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/industrialPark/info.vue')
  },
  {
    path: '/activity',
    name: 'activity',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/activity/index.vue')
  },
  {
    path: '/park',
    name: 'park',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/park/index.vue')
  },
  {
    path: '/chart',
    name: 'chart',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/chart/index.vue')
  },
  {
    path: '/chart/info',
    name: 'chart/info',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/chart/info.vue')
  },
  {
    path: '/404',
    name: '404',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/common/404.vue')
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', to.fullPath]);
    }
  }
  next();
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.length === 0) {
//     next({ path: '/404' });
//   } else {
//     next();
//   }
// });


// // 在路由切换前保存状态
// router.beforeEach((to, from, next) => {
//   // 保存 from 组件的状态到某个地方（例如 Vuex 或其他持久化存储）
//   next()
// })
//
// // 在路由切换后恢复状态
// router.afterEach((to, from) => {
//   // 从保存的地方恢复 from 组件的状态
// })


export default router
