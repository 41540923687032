export default {
    install(Vue) {
        Vue.prototype.$customNavigate = function(pagePath, pages) {
            // 判断当前环境是否为小程序，执行相应的导航
            const ua = navigator.userAgent.toLowerCase();
            let isWXWork = ua.match(/wxwork/i) == 'wxwork'
            let isWeixin = !isWXWork && ua.match(/micromessenger/i) == 'micromessenger'
            let isMobile = false
            let isDesktop = false
            if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i)) {
                isMobile = true
            } else {
                isDesktop = true
            }
            if (isWeixin) { // 在微信中打开
                wx.miniProgram.getEnv((res) => {
                    if (res.miniprogram) { // 小程序中直接跳转
                        uni.navigateTo({
                            url: pages // 使用传递的页面路径
                        });
                    } else { // 微信浏览器中打开小程序
                        this.$api.www.getWechatUrlLink(pages).then(res=>{
                            window.location.href = res.data
                        })
                    }
                });
            } else if (isDesktop) { // pc端直接跳转
                const currentPath = this.$route.path;
                if (currentPath !== pagePath) {
                    this.$router.push(pagePath);
                }
            } else if (isMobile) { // 手机端打开小程序
                this.$api.www.getWechatUrlLink(pages).then(res=>{
                    window.location.href = res.data
                })
            } else { // pc端直接跳转
                const currentPath = this.$route.path;
                if (currentPath !== pagePath) {
                    this.$router.push(pagePath);
                }
            }
        };
    }
};
