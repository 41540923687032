<template>
  <div>
    <!-- Page Loader-->
    <div id="page-loader" class="bw">
      <div class="loader-item"> <img src="../assets/img/other/oval.svg" alt="page loader"> </div>
    </div>
    <!--网页正文部分-->
<!--    <div id="nav" class="navbar navbar-pasific navbar-mp megamenu navbar-fixed-top">-->
<!--      <div class="container-fluid">-->
<!--        <div class="navbar-header">-->
<!--          <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-main-collapse"> <i class="fa fa-bars"></i> </button>-->
<!--          <a class="navbar-brand page-scroll" href="/"> <img src="../assets/img/logo/logo-default.png" alt="logo" class="toplogo"> 高铁集团招商运营平台 </a>-->
<!--        </div>-->
<!--        <div class="navbar-collapse collapse navbar-main-collapse">-->
<!--          <ul class="nav navbar-nav">-->
<!--            <li ><a href="#"  class="color-light active">首页</a></li>-->
<!--            <li v-for="(item, index) in top" :key="index">-->
<!--              <a href="#" class="color-light">{{item.navName}}</a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a href="#" data-toggle="modal" data-target="#searchModal">-->
<!--                <i class="fa fa-search fa-fw color-pasific"></i>-->
<!--              </a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <Top></Top>

    <div v-if="!wx" class="intro pt100 pb100 parallax-window background-cover" data-parallax="scroll" data-speed="0.5" :style="'background: url('+getWebsiteInfo.headerImg+');'">
      <div class="intro-body">
        <div class="container">
          <div class="row">
            <div class="col-md-12 mt-100">
              <!-- 在手机端显示img-text-2.png -->
              <img class="mobile-img iimg" style="border-radius: 10px" src="../assets/img/bg/img-text-2.png" />
              <!-- 在其他设备显示img-text-1.png -->
              <img class="desktop-img iimg" style="border-radius: 10px" src="../assets/img/bg/img-text-1.png" />
              <h3 class="mt10 mb25 color-gray animated bannersubtext" data-animation="fadeInUp" data-animation-delay="200">{{getWebsiteInfo.headerRow2}}</h3>
              <a class="button button-pasific button-lg hover-ripple-out animated" data-animation="fadeInUp" data-animation-delay="300"  @click="toAbout">了解更多</a> </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="intro pt100 pb100 parallax-window background-cover" data-parallax="scroll" data-speed="0.5" :style="'background: url('+getWebsiteInfo.headerImg+');'">
      <div class="intro-body">
        <div class="container">
          <div class="row">
            <div class="col-md-12 mt-100">
              <!-- 在手机端显示img-text-2.png -->
              <img class="mobile-img iimg" src="../assets/img/bg/img-text-n2.png" />
              <!-- 在其他设备显示img-text-1.png -->
              <img class="desktop-img iimg" src="../assets/img/bg/img-text-n2.png" />
              <h3 class="mt10 mb25 color-gray animated bannersubtext" data-animation="fadeInUp" data-animation-delay="200">{{getWebsiteInfo.headerRow2}}</h3>
              <a class="button button-pasific button-lg hover-ripple-out animated" data-animation="fadeInUp" data-animation-delay="300"  @click="toAbout">点击进入小程序</a> </div>
          </div>
        </div>
      </div>
    </div>

    <!--新闻栏目-->
    <div id="news" class="pt75 pb50">
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-lg-5">
<!--            <div id="owl-homenews" class="owl-carousel" style="cursor: pointer;">-->
<!--              &lt;!&ndash; Testimonial Item &ndash;&gt;-->
<!--              <div v-for="(item, index) in carouselNewsList" :key="index" class="testimonial testimonial-center" @click="toNewsInfo(item)">-->
<!--                <img style="height: 305px;" :src="item.coverImage || 'https://railway-park.oss-cn-shanghai.aliyuncs.com/about/adoutImg/2023/05/1684308647743.jpg'" alt="" >-->
<!--              </div>-->
<!--            </div>-->
            <div id="carousel-example-generic" class="carousel slide" style="cursor: pointer;" data-ride="carousel">
              <!-- 轮播指示器 -->
              <ol class="carousel-indicators">
                <li :data-target="'#carousel-example-generic'" :data-slide-to="index" v-for="(item, index) in carouselNewsList" :class="{ active: index === 0 }"></li>
              </ol>

              <!-- 轮播内容 -->
              <div class="carousel-inner" role="listbox">
                <div class="item" :class="{ active: index === 0 }" v-for="(item, index) in carouselNewsList" @click="toNewsInfo(item)">
                  <img :src="item.coverImage" style="height: 300px;width: 100%">
                </div>
              </div>
              <!-- 轮播控制按钮 -->
              <a class="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
                <span class="glyphicon glyphicon-chevron-left"></span>
              </a>
              <a class="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
                <span class="glyphicon glyphicon-chevron-right"></span>
              </a>
            </div>
          </div>
          <div class="col-md-7">
<!--            <ul class="nav nav-pills">-->
<!--              <li style="cursor:default;" v-for="(item, index) in newsColumnList" :key="index" :class="index==0?'active':''" @click="getNewsList(item.ucId)">-->
<!--                <a data-toggle="pill">{{item.name}}</a>-->
<!--              </li>-->
<!--            </ul>-->
            <div class="tab-content">
              <div class="tab-pane fade in active" style="padding-top: 0;">
                <ul class="newslist" v-if="newsList.length > 0" style="cursor:pointer;">
                  <li v-for="(item, index) in newsList" :key="index" @click="toNewsInfo(item)">
                    <a>
                      <span class="newsleft">{{item.title}}</span>
                      <span class="pull-right date">{{item.publishDate|birthDateFormat}}</span></a>
                  </li>
                </ul>
                <div v-else style="margin-left: 100px">
                  <img src="../assets/img/bg/null-news.svg">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid bg-gray2 pt40 pb40">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="fact">
              <div class="fact-number timer" data-perc="10">
                <span class="factor color-pasific">1</span>
                <span class="factinfo color-pasific">+ 年</span>
              </div>
              <span class="fact-title">园区运营经验</span>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="fact">
              <div class="fact-number timer" data-perc="800">
                <span class="factor color-pasific">1</span>
                <span class="factinfo color-pasific">+ 家</span>
              </div>
              <span class="fact-title">园区企业</span>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="fact">
              <div class="fact-number timer" data-perc="1000">
                <span class="factor color-pasific">1</span>
                <span class="factinfo color-pasific">+ 人</span>
              </div>
              <span class="fact-title">产业员工</span>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="fact">
              <div class="fact-number timer" data-perc="5000">
                <span class="factor color-pasific">1</span>
                <span class="factinfo color-pasific">+ 亿</span>
              </div>
              <span class="fact-title">产出贡献</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--走进临港-->
    <div id="about" class="pt75 pb50">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-6 col-xs-12">
            <h3> {{getAboutInfo.title}} <small class="heading heading-solid"></small> </h3>
            <p class="text-16 lineh-30" style="color: #747474;">
              {{getAboutInfo.aboutDescription}}
            </p>
            <p @click="toAbout1"><a class="button button-sm button-pasific mt20 hover-ripple-out animated" data-animation="slideInRight" data-animation-delay="100">了解更多</a> </p>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12"> <img :src="getAboutInfo.adoutImg" alt="about us" class="img-responsive center-block"> </div>
        </div>
        <div class="row mt80">
          <div v-for="(item, index) in central" :key="index" class="col-md-2 col-sm-2 col-xs-4 animated fadeInLeft visible" data-animation="fadeInLeft" data-animation-delay="100">
            <div class="content-box content-box-center">
              <p class="serviceicon">
                <img :src="item.navIcon" alt="about us" class="img-responsive center-block">
              </p>
              <h5>{{item.navName}}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--园区活动-->
<!--    <div id="team" class="pt75 pb75 bg-gray2">-->
<!--      <div class="container">-->
<!--        <div class="row">-->
<!--          <div class="col-md-12 text-center">-->
<!--            <h1 class="font-size-normal"> 园区活动 <small class="heading heading-solid center-block"></small> </h1>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="container">-->
<!--        <div class="row">-->
<!--          <div class="col-md-3 col-sm-6 col-xs-12 mt30" v-for="(item, index) in activityTypeList" :key="index" @click="toActivity(item)">-->
<!--            <a class="yqhdbox" style="cursor:pointer;">-->
<!--              <div class="team team-two"> <img :src="item.cover" alt="" class="img-responsive">-->
<!--                <h5>{{item.name}}</h5>-->
<!--                <p class="pl10 pr10 mb10">{{item.description}}</p>-->
<!--                <span><i class="fa fa-fw fa-long-arrow-right"></i></span>-->
<!--              </div>-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <!--20大-->
    <div id="info-1" class="pt50 pb50 parallax-window-7" data-parallax="scroll" data-speed="0.5" :style="'background: url('+getWebsiteInfo.footerImg+')'">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <h1 class="color-light"> {{getWebsiteInfo.footeTitle}}</h1>
            <a class="button button-md button-pasific hover-ripple-out mt25" @click="toDj">了解更多</a> </div>
        </div>
      </div>
    </div>

    <!--园区掠影--->
    <div id="shop" class="pt70 pb50" style="cursor: pointer">
      <div class="container-fluid">
        <div class="row text-center mb25">
          <h1 class="font-size-normal">园区掠影<small class="heading heading-solid center-block"></small> </h1>
        </div>
        <div class="row text-center">
          <div class="col-md-12">
            <div id="owlShop">
              <!-- shop item start -->
              <div v-for="(item, index) in parkImgList" :key="index" @click="toPark(item)" class="shop-item-container-out"> <img :src="item.img" alt="shop item" class="img-responsive center-block"> </div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <friendly-link></friendly-link>-->
    <Footer></Footer>
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel'
import imagesLoaded from "imagesloaded"
import Footer from "@/components/footer/index.vue";
import Top from "@/components/top/index.vue";
import FriendlyLink from "@/components/friendly-link";
import { mapGetters } from 'vuex';

export default {
  name: "index",
  components:{
    FriendlyLink,
    carousel,
    Footer,
    Top
  },
  el: "#myCarousel",
  data() {
    return {
      parkImgList: [],
      activityTypeList: [],
      newsColumnList: [],
      newsList: [],
      top: [],
      central: [],
      bottom: [],
      carouselNewsList: [],
      aboutInfo: this.$store.getters.getAboutInfo,
      websiteInfo: this.$store.getters.getWebsiteInfo,
      wx: false
    }
  },
  computed: {
    ...mapGetters(['getWebsiteInfo', 'getAboutInfo']),
  },
  created() {
    this.isWx()
    $('#carousel-example-generic').carousel({
      interval: 100, // 设置切换间隔时间（以毫秒为单位）
      pause: 'hover', // 鼠标悬停时暂停切换
      wrap: true, // 循环切换
      swipe: true // 启用滑动切换
    });
    this.init()
    $(function($) {
      "use strict";
      $('.animated').appear(function() {
        var elem = $(this);
        var animation = elem.data('animation');
        if ( !elem.hasClass('visible') ) {
          var animationDelay = elem.data('animation-delay');
          if ( animationDelay ) {

            setTimeout(function(){
              elem.addClass( animation + " visible" );
            }, animationDelay);

          } else {
            elem.addClass( animation + " visible" );
          }
        }
      });
    });
    function animateNumbers() {
      var timers = $(".timer");

      timers.each(function() {
        var percent = $(this).data("perc");
        var number = 1;

        var increment = Math.ceil(percent / 50); // Increase the number by a larger value for visual effect

        var observer = new IntersectionObserver(
            function(entries, observer) {
              entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                  var interval = setInterval(function() {
                    if (number <= percent) {
                      $(entry.target).find(".factor").text(number);
                      number += increment;
                    } else {
                      $(entry.target).find(".factor").text(percent); // Set the final number
                      clearInterval(interval);
                    }
                  }, 40); // Decrease the interval for faster animation

                  observer.unobserve(entry.target);
                }
              });
            }
        );

        observer.observe(this);
      });
    }
    // Call the animateNumbers function when the document is ready
    $(document).ready(function() {
      animateNumbers();
    });
  },
  methods: {
    isWx() {
      // 判断当前环境是否为小程序
      const ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            this.wx = true
          }
        });
      }
    },
    init() {
      this.getUcParkImgList()
      // this.getUcActivityTypeList()
      // this.getUcNewsColumnList()
      this.getCarouselNewsList()
      this.getNewsList()
      let imgLoad = imagesLoaded('body');
      imgLoad.on( 'always', function() {
        console.log('ALWAYS - all images have been loaded');
        $(".loader-item").delay(10).fadeOut();
        $("#page-loader").delay(10).fadeOut("slow");
      })
    },
    // getUcHomeNavigationList() {
    //   this.$api.www.getUcHomeNavigationList({}).then(res=>{
    //     this.top = res.data.top
    //     this.central = res.data.central
    //     this.bottom = res.data.bottom
    //   })
    // },
    getUcParkImgList() {
      this.$api.www.getUcParkImgList({}).then(res=>{
        this.parkImgList = res.data
        this.$nextTick(function () {
          $(function() {
            "use strict";
            var owlShop = $("#owlShop");
            owlShop.owlCarousel({
              slideSpeed : 1000,
              autoPlay : true,
              pagination: false,
              items : 4,
            });
            $(".shop-control-next").on('click', function(){
              owlShop.trigger('owl.next');
            })
            $(".shop-control-prev").on('click', function(){
              owlShop.trigger('owl.prev');
            });
          });
        })

      })
    },
    getUcActivityTypeList() {
      this.$api.www.getUcActivityTypeList({show: true}).then(res=>{
        this.activityTypeList = res.data
      })
    },
    getUcNewsColumnList() {
      this.$api.www.getUcNewsColumnList({isShow: true, isHome: true}).then(res=>{
        this.newsColumnList = res.data
        if (this.newsColumnList.length > 0) {
          this.getNewsList(this.newsColumnList[0].ucId)
        }
      })
    },
    getNewsList(columnId) {
      this.$api.www.getUcNewsPage({pageNum: 1, pageSize:7, isShow: true, status: 1}).then(res=>{
        this.newsList = res.data.list
      })
    },
    getUcAdout() {
      this.$api.www.getUcAdout({}).then(res=>{
        this.aboutInfo = res.data
      })
    },
    getUcWebsiteInfoVO() {
      this.$api.www.getUcWebsiteInfoVO({}).then(res=>{
        this.websiteInfo = res.data
      })
    },
    getCarouselNewsList(columnId) {
      this.$api.www.getUcNewsPage({pageNum: 1, pageSize:10, isShow: true, carousel: true}).then(res=>{
        this.carouselNewsList = res.data.list
        this.$nextTick(function () {
          $(function() {
            "use strict";
            $("#owl-homenews").owlCarousel({
              slideSpeed : 1000,
              autoPlay : true,
              dots : true,
              items : 1,
            });
          });
        })
      })
    },
    toNewsInfo(item) {
      // 判断当前环境是否为小程序
      const ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            uni.navigateTo({
              url: '/pages/public/news/info?ucId='+item.ucId
            });
          } else {
            this.$router.push("/news/info?navId=19&ucId="+item.ucId)
          }
        });
      } else {
        this.$router.push("/news/info?navId=19&ucId="+item.ucId)
      }
    },
    toAbout1() {
      // 判断当前环境是否为小程序
      const ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            uni.navigateTo({
              url: '/pages/public/about/list'
            });
          } else {
            this.$router.push("/about?navId=9")
          }
        });
      } else {
        this.$router.push("/about?navId=9")
      }
    },
    toAbout() {
      // 判断当前环境是否为小程序
      const ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            uni.navigateBack({
              delta: 1
            });
          } else {
            this.$customNavigate('/about?navId=9', '/pages/public/about/list');
            // this.$router.push("/about?navId=9")
          }
        });
      } else {
        this.$customNavigate('/about?navId=9', '/pages/public/about/list');
        // this.$router.push("/about?navId=9")
      }
    },
    toInfo(item) {
      this.$router.push("/news/info?ucId="+item.ucId+'&columnId='+item.columnId)
    },
    toActivity(item) {
      this.$router.push("/activity?ucId="+item.ucId)
    },
    toPark(item) {
      this.$router.push("/park?id="+item.id)
    },
    toDj() {
      // 判断当前环境是否为小程序
      const ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            uni.navigateTo({
              url: '/pages/public/serve/list?navId=12'
            });
          } else {
            this.$router.push("/serve?navId=12")
          }
        });
      } else {
        this.$router.push("/serve?navId=12")
      }
    }
  }
}
</script>

<style scoped>
.background-cover {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100% !important;
  height: 100vh;
}

.mobile-img {
  display: none; /* 默认隐藏手机端的图片 */
}

.iimg {
  width: 100%;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  /* 在屏幕宽度小于等于768px时显示手机端的图片 */
  .mobile-img {
    display: block;
  }

  /* 隐藏其他设备的图片 */
  .desktop-img {
    display: none;
  }
}
</style>
