import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    getUcLinksList(data){
        return request({
            url: getUrl("webSite/getUcLinksList"),
            method: "post",
            data,
        })
    },
    getUcParkImgList(data){
        return request({
            url: getUrl("webSite/getUcParkImgList"),
            method: "post",
            data,
        })
    },
    getUcActivityTypeList(data){
        return request({
            url: getUrl("webSite/getUcActivityTypeList"),
            method: "post",
            data,
        })
    },
    getUcNewsColumnList(data){
        return request({
            url: getUrl("webSite/getUcNewsColumnList"),
            method: "post",
            data,
        })
    },
    getUcNewsList(data){
        return request({
            url: getUrl("webSite/getUcNewsList"),
            method: "post",
            data,
        })
    },
    getUcNewsPage(data){
        return request({
            url: getUrl("webSite/getUcNewsPage"),
            method: "post",
            data,
        })
    },
    getUcHomeNavigationList(data){
        return request({
            url: getUrl("webSite/getUcHomeNavigationList"),
            method: "post",
            data,
        })
    },
    getUcNews(data){
        return request({
            url: getUrl("webSite/getUcNews"),
            method: "post",
            data,
        })
    },
    getUcAdout(data){
        return request({
            url: getUrl("webSite/getUcAdout"),
            method: "post",
            data,
        })
    },
    getUcWebsiteInfoVO(data){
        return request({
            url: getUrl("webSite/getUcWebsiteInfoVO"),
            method: "post",
            data,
        })
    },
    getUcNavigationList(data){
        return request({
            url: getUrl("webSite/getUcNavigationList"),
            method: "post",
            data,
        })
    },
    getUcServeVo(data){
        return request({
            url: getUrl("webSite/getUcServeVo"),
            method: "post",
            data,
        })
    },
    getUcServePage(data){
        return request({
            url: getUrl("webSite/getUcServePage"),
            method: "post",
            data,
        })
    },
    newsReda(data){
        return request({
            url: getUrl("webSite/newsReda"),
            method: "post",
            data,
        })
    },
    getUcActivityInfoPage(data){
        return request({
            url: getUrl("webSite/getUcActivityInfoPage"),
            method: "post",
            data,
        })
    },
    getUcCommercialDistrictsInfoPage(data){
        return request({
            url: getUrl("webSite/getUcCommercialDistrictsInfoPage"),
            method: "post",
            data,
        })
    },
    getUcCommercialDistrictsInfoList(data){
        return request({
            url: getUrl("webSite/getUcCommercialDistrictsInfoList"),
            method: "post",
            data,
        })
    },
    getUcCommercialDistrictsInfoVO(data){
        return request({
            url: getUrl("webSite/getUcCommercialDistrictsInfoVO"),
            method: "post",
            data,
        })
    },
    addUcCustomInfo(data){
        return request({
            url: getUrl("webSite/addUcCustomInfo"),
            method: "post",
            data,
        })
    },
    getUcIndustrialParkList(data){
        return request({
            url: getUrl("webSite/getUcIndustrialParkList"),
            method: "post",
            data,
        })
    },
    getUcIndustrialParkVo(data){
        return request({
            url: getUrl("webSite/getUcIndustrialParkVo"),
            method: "post",
            data,
        })
    },
    saveUcIntendedCustomer(data){
        return request({
            url: getUrl("webSite/saveUcIntendedCustomer"),
            method: "post",
            data,
        })
    },
    getSpaceGoodsList(data){
        return request({
            url: getUrl("webSite/getSpaceGoodsList"),
            method: "post",
            data,
        })
    },
    getBuildingFloorList(data){
        return request({
            url: getUrl("webSite/getBuildingFloorList"),
            method: "post",
            data,
        })
    },
    getWechatUrlLink(path){
        return request({
            url: getUrl("webSite/getWechatUrlLink?path="+path),
            method: "get",
        })
    },
}
