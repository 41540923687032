<template>
  <!--网页正文部分-->
  <div>
    <div id="nav" class="navbar navbar-pasific navbar-mp megamenu navbar-fixed-top">
      <div class="container-fluid">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-main-collapse"> <i class="fa fa-bars"></i> </button>
          <a class="navbar-brand page-scroll top-nav" href="/"> <img :src="getWebsiteInfo.logo" alt="logo" class="toplogo"> {{getWebsiteInfo.name}} </a>
<!--          <a  class="navbar-brand page-scroll" href="/"> <img :src="getWebsiteInfo.logo" alt="logo" class="toplogo"> {{getWebsiteInfo.name}} </a>-->
        </div>

        <div class="navbar-collapse collapse navbar-main-collapse">
          <ul class="nav navbar-nav navq" style="cursor: pointer">
            <li><a @click="to({})" :class="navId==null?'color-light active':'color-light'">首页</a></li>
            <li v-for="(item, index) in getNavList.top" :key="index" class="dropdown" @mouseenter="showDropdown(index)" @mouseleave="hideDropdown">
              <a @click="toColumn(item)" class="dropdown-toggle" role="button" :class="{'color-light': true, 'active': navId==item.id}">
                {{item.navName}}
              </a>
              <ul v-if="hasMatchingLevel2(item)" class="dropdown-menu" style="background-color: rgba(0, 0, 0, 0.7); min-width: unset; margin-left: 10px; color: #fff !important; border: none;border-radius: 5px;" :class="{ 'show': activeDropdownIndex === index }" @mouseenter="cancelHideTimeout" @mouseleave="startHideTimeout">
                <li v-for="(level, index) in getNavList.level2" :key="index" v-if="level.parentId === item.id" @click="checkColumn(level, item)">
                  <a class="light">{{level.navName}}</a>
                </li>
              </ul>
            </li>
            <li id="modal-btn-js1" v-if="!wx">
              <a href="#" data-toggle="modal1" data-target="#myModal1" id="search-toggle1">
                <i class="fa fa-search fa-fw color-pasific"></i>
              </a>
            </li>
            <li id="search-input" class="hide-search-input">
              <form class="navbar-form" onsubmit="return false;">
                <input v-model="customize" placeholder="请输入关键词搜索" type="text" @keydown.enter="find" class="form-control transparent">
                <a @click="find">
                  <i class="fa fa-search fa-fw color-pasific"></i>
                </a>
                <a id="close-search1">
                  <i class="fa fa-times fa-fw color-pasific"></i>
                </a>
              </form>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "index",
  data() {
    return {
      top: [],
      wx: false,
      path: '/',
      navId: this.$route.query.navId,
      customize: '',
      share: false,
      activeDropdownIndex: null,
      hideTimeout: null
    }
  },
  computed: {
    ...mapGetters(['getWebsiteInfo', 'getAboutInfo', 'getNavList', 'getNavId']),
    hasMatchingLevel2: function() {
      return function(item) {
        // 检查是否存在与topId相匹配的level2元素
        return this.getNavList.level2.some(level => level.parentId === item.id);
      }
    }
  },
  created() {
    this.isWx()
    $(document).ready(function() {
      $('#search-toggle1').click(function(e) {
        e.preventDefault();
        $('#search-input').toggleClass('show-search-input');
        $('#modal-btn-js').toggleClass('show-search-toggle');
        $('.navq > li:not(#search-input)').toggle();
      });
      $('#close-search1').click(function(e) {
        e.preventDefault();
        $('#search-input').removeClass('show-search-input');
        $('#modal-btn-js').removeClass('show-search-toggle');
        $('.navq > li:not(#search-input)').toggle();
      });
    });
    //清空
    $("#btn_Clear").click(function() {
      $("#form_Condition").resetForm();
    });
    //查询(button模式时需开,submit模式时需关,bug,按钮为submit时就不能再绑定了,因为已默认绑定,再绑定就会导致查询2次)
    $("#btn_Search").click(function() {
      $("#tb_Table").bootstrapTable('refresh');
    });
    //回车查询(button模式)(全页面-解决下拉框及日期选择无法回车查询的问题)
    document.onkeydown = function (e) {
      var theEvent = e || window.event;
      var code = theEvent.keyCode || theEvent.which || theEvent.charCode;
      if (code == 13) {
        $('#btn_Search').click();
      }
    };
  },
  mounted() {
    this.path = this.$route.path
    this.customize = this.$route.query.customize
  },
  methods: {
    showDropdown(index) {
      clearTimeout(this.hideTimeout);
      this.activeDropdownIndex = index;
    },
    hideDropdown() {
      this.hideTimeout = setTimeout(() => {
        this.activeDropdownIndex = null;
      }, 200);
    },
    cancelHideTimeout() {
      // clearTimeout(this.hideTimeout);
    },
    startHideTimeout() {
      // this.hideDropdown();
    },
    checkColumn(level, item) {
      let targetRoute = ""
      if (level.type !== 'url') {
        targetRoute = "/serve?navId=" + item.id + "&ucId=" + level.ucId;
      } else {
        targetRoute = level.url;
      }
      const currentRoute = this.$route.fullPath;
      if (targetRoute !== currentRoute) {
        this.$router.replace(targetRoute);
      }
    },
    init() {
      this.getUcHomeNavigationList()
    },
    getUcHomeNavigationList() {
      this.$api.www.getUcHomeNavigationList({}).then(res=>{
        this.top = res.data.top
      })
    },
    find() {
      if (this.customize == null || this.customize === '') {
        // customize为空，显示Bootstrap的错误提示框
        if (this.navId == this.$route.query.navId) {
          $('#mymodal-js').modal('hide')
          // this.$router.push("/news?navId=19")
          window.location.href = "/news?navId=19";
        }
      } else {
        // 进行搜索操作
        $('#mymodal-js').modal('hide')
        // this.$router.push("/news?navId=19&customize=" + this.customize);
        window.location.href = "/news?navId=19&customize=" + this.customize;
      }
    },
    to(item) {
      let a = this.$route.path+'?navId='+this.$route.query.navId
      let b = item.navLink+'?navId='+item.id
      if (item && item.navLink) {
        if (a != b) {
          this.$router.push(item.navLink+"?navId="+item.id)
        }
      } else {
        if (this.$route.path !== '/') {
          this.$router.push('/')
        }
      }
    },
    toColumn(item) {
      // 判断当前环境是否为小程序
      const ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            uni.navigateTo({
              url: '/pages/public'+item.navLink+'/list?navId='+item.id
            });
          } else {
            this.to(item)
          }
        });
      } else {
        this.to(item)
      }
    },
    isWx() {
      // 判断当前环境是否为小程序
      const ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        this.wx = true
        // wx.miniProgram.getEnv((res) => {
        //   if (res.miniprogram) {
        //     this.wx = true
        //   }
        // });
      }
    }
  }
}
</script>

<style scoped>
.navq {
  font-size: 24px;
  font-family: SF Pro;
  /*font-weight: bold;*/
  color: #FFFFFF;
  opacity: 1;
}
.btn-no-outline:focus {
  outline: none !important;
  box-shadow: none !important;
}

.hide-search-input {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease, height 0.3s ease;
  position: absolute;
  top: 100%; /* 或者根据需要设置合适的值 */
  left: 0;
  width: 100%;
  z-index: -1;
}
.top-nav {
   /*width: 100% !important;*/
   /*white-space: nowrap; !* 防止内容换行 *!*/
   /*overflow: hidden; !* 隐藏超出容器宽度的内容 *!*/
   /*text-overflow: ellipsis; !* 使用省略号代替溢出内容 *!*/
}

@media screen and (max-width: 600px) {
  .top-nav {
    width: 80% !important;
    white-space: nowrap; /* 防止内容换行 */
    overflow: hidden; /* 隐藏超出容器宽度的内容 */
    text-overflow: ellipsis; /* 使用省略号代替溢出内容 */
  }
}
#search-input.show-search-input {
  opacity: 1;
  height: auto;
  transition: opacity 0.5s ease, height 0.5s ease;
  position: static;
  z-index: auto;
}

#search-input form {
  margin-top: 3px;
}

#search-input .form-group {
  position: relative;
}

#search-input input {
  width: 300px;
}

#search-input a {
  margin-left: 20px;
}

.transparent {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
}
</style>
