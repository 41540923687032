<template>
  <div>
    <div class="plug_service am-animation-slide-bottom">
      <ul>
        <li>
          <a style="border-bottom: 1px solid #ececec;">
            <img style="width: 35px;height: 35px" src="https://railway-park.oss-cn-shanghai.aliyuncs.com/news/cover/2023/07/1689930462455.svg" />
            <div>官网微信</div>
          </a>
          <div class="content-container-gfwx">
            <img :src="getWebsiteInfo.qrcode" style="width: 100px;height: 100px;border-radius: 10px">
          </div>
        </li>
        <li>
          <a style="border-bottom: 1px solid #ececec;">
            <img style="width: 35px;height: 35px" src="https://railway-park.oss-cn-shanghai.aliyuncs.com/news/cover/2023/07/1689930994596.svg" />
            <div>客服热线</div>
            <div class="content-container-kfrx">
              <div style="text-align: center;font-size: 15px;font-weight: bold">0991-3764304</div>
              <div>工作时间：10:30-19:00</div>
            </div>
          </a>
        </li>
        <li @click="openModal">
          <a>
            <img style="width: 35px;height: 35px" src="https://railway-park.oss-cn-shanghai.aliyuncs.com/news/cover/2023/07/1689931466976.svg" />
            <div>信息填报</div>
          </a>
        </li>
      </ul>
    </div>

    <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document"  style="max-width: 800px;">
        <div class="modal-content">
          <div class="modal-header" style="border: none">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="myModalLabel">客户信息填报</h4>
          </div>
          <div class="modal-body" >
            <form @submit="submitForm">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">姓名:</label>
                    <input type="text" class="form-control" id="name" v-model="formData.name" placeholder="输入姓名" maxlength="20" required>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="email">联系邮箱:</label>
                    <input type="email" class="form-control" id="email" v-model="formData.email" placeholder="输入联系邮箱">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="phone">联系电话/手机:</label>
                    <input type="text" class="form-control" id="phone" v-model="formData.phone" placeholder="请输入手机号" pattern="^(\d{3}-\d{8}|\d{4}-\d{7}|\d{11})$" title="请输入正确的联系电话或手机格式" required>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="company">公司名称:</label>
                    <input type="text" class="form-control" id="company" v-model="formData.companyName" placeholder="输入公司名称" maxlength="50">
                  </div>
                </div>
              </div>
<!--              <div class="row">-->
<!--                <div class="col-md-6">-->
<!--                  <div class="form-group">-->
<!--                    <label for="range">经营行业/范围:</label>-->
<!--                    <select class="form-control" id="range"  v-model="formData.range" required>-->
<!--                      <option value="" disabled selected>请选择经营行业/范围</option>-->
<!--                      <option value="IT互联网">IT互联网</option>-->
<!--                      <option value="电子商务">电子商务</option>-->
<!--                      <option value="教育培训">教育培训</option>-->
<!--                      <option value="文化娱乐">文化娱乐</option>-->
<!--                      <option value="房产服务">房产服务</option>-->
<!--                      <option value="房产服务">医疗健康</option>-->
<!--                      <option value="其它">其它</option>-->
<!--                    </select>-->
<!--                    &lt;!&ndash;                    <input type="text" class="form-control" id="range" v-model="formData.range" placeholder="输入经营项目/范围" maxlength="50" required>&ndash;&gt;-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-md-6">-->
<!--                  <div class="form-group">-->
<!--                    <label for="capital">注册资本:</label>-->
<!--                    <select class="form-control" id="capital"  v-model="formData.capital" required>-->
<!--                      <option value="" disabled selected>请选择注册资本</option>-->
<!--                      <option value="100万以下">100万以下</option>-->
<!--                      <option value="100-500万">100-500万</option>-->
<!--                      <option value="500-2000万">500-2000万</option>-->
<!--                      <option value="2000万以上">2000万以上</option>-->
<!--                    </select>-->
<!--                    &lt;!&ndash;                    <input type="text" class="form-control" id="capital" v-model="formData.capital" placeholder="输入注册资本（元）" pattern="^\d+$" title="请输入正确数字（整数）" maxlength="50" required>&ndash;&gt;-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div class="col-md-6">-->
<!--                  <div class="form-group">-->
<!--                    <label for="area">期望面积:</label>-->
<!--                    <select class="form-control" id="area"  v-model="formData.area" required>-->
<!--                      <option value="" disabled selected>请选择期望面积</option>-->
<!--                      <option value="100平方米以下">100平方米以下</option>-->
<!--                      <option value="100-300平方米">100-300平方米</option>-->
<!--                      <option value="300-500平方米">300-500平方米</option>-->
<!--                      <option value="500平方米以上">500平方米以上</option>-->
<!--                    </select>-->
<!--                    &lt;!&ndash;                    <input type="text" class="form-control" id="area" v-model="formData.area" placeholder="输入期望面积（单位：平方米）" pattern="^\d+(\.\d{1,2})?$" title="请输入正确数字（小数点最多2位）" required>&ndash;&gt;-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-md-6">-->
<!--                  <div class="form-group">-->
<!--                    <label for="settledTime">期望入驻时间:</label>-->
<!--                    <select class="form-control" id="settledTime"  v-model="formData.settledTime" required>-->
<!--                      <option value="" disabled selected>请选择期望入驻时间</option>-->
<!--                      <option value="一个月内">一个月内</option>-->
<!--                      <option value="1-3个月内">1-3个月内</option>-->
<!--                      <option value="3-12个月内">3-12个月内</option>-->
<!--                    </select>-->
<!--                    &lt;!&ndash;                    <input type="date" class="form-control" id="settledTime" v-model="formData.settledTime" :min="'1970-01-01'" required v-restrict-keyboard>&ndash;&gt;-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div class="col-md-6">-->
<!--                  <div class="form-group">-->
<!--                    <label for="gender">投资能力:</label>-->
<!--                    <select class="form-control" id="gender"  v-model="formData.gender" required>-->
<!--                      <option value="" disabled selected>请选择投资能力</option>-->
<!--                      <option value="100万以下">100万以下</option>-->
<!--                      <option value="100-500万">100-500万</option>-->
<!--                      <option value="500-2000万">500-2000万</option>-->
<!--                      <option value="2000万以上">2000万以上</option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-md-6">-->
<!--                  <div class="form-group">-->
<!--                    <label for="scale">公司规模:</label>-->
<!--                    <select class="form-control" id="scale"  v-model="formData.scale" required>-->
<!--                      <option value="" disabled selected>请选择公司规模</option>-->
<!--                      <option value="微型企业（10人以下）">微型企业（10人以下）</option>-->
<!--                      <option value="小型企业（10-50人）">小型企业（10-50人）</option>-->
<!--                      <option value="中型企业（50-300人）">中型企业（50-300人）</option>-->
<!--                      <option value="大型企业（300-1000人）">大型企业（300-1000人）</option>-->
<!--                      <option value="超大型企业（1000人以上）">超大型企业（1000人以上）</option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="scale">入驻类型:</label>
                    <select class="form-control" id="type"  v-model="formData.type" required>
                      <option value="" disabled selected>请选择入驻类型</option>
                      <option :value="1">商圈入驻</option>
                      <option :value="2">产业园入驻</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="demand">意向描述:</label>
                <textarea class="form-control" id="demand" v-model="formData.description" rows="5" placeholder="输入意向描述（最多1000字）" maxlength="200"></textarea>
              </div>
              <div class="text-right">
                <button type="submit" class="button button-pasific hover-ripple-out" :disabled="addLoading">{{addLoading?'提交中...':'提交'}}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['getWebsiteInfo']),
  },
  name: "index",
  data() {
    return {
      formData: {
        scale: '中型企业（50-300人）',
        range: '',
        capital: '100-500万',
        area: '100-300平方米',
        gender: '100-500万',
        settledTime: '一个月内',
        channel: 3,
        type: 1
      },
      addLoading:false,
    }
  },
  directives: {
    restrictKeyboard: {
      inserted(el) {
        el.addEventListener('keydown', (event) => {
          event.preventDefault();
        });
      }
    }
  },
  mounted() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    const currentDate = `${year}-${month}-${day}`;
    document.getElementById('settledTime').min = currentDate;
  },
  methods: {
    openModal() {
      // 使用Bootstrap的弹出框API来打开模态框
      $('#myModal').modal('show');
    },
    closeModal() {
      // 使用Bootstrap的弹出框API来关闭模态框
      $('#myModal').modal('hide');
    },
    submitForm(event) {
      event.preventDefault(); // 阻止表单的默认提交行为
      // 在这里可以处理表单的提交逻辑，如发送请求等
      console.log('表单提交:', this.formData);
      this.addLoading = true
      // this.formData.type = 2
      this.formData.channel = 3
      this.$api.www.saveUcIntendedCustomer(this.formData).then(res=>{
        this.addLoading = false
        this.closeModal()
      }).catch(error => {
        this.addLoading = false
        this.closeModal()
      })
    }
  }
}
</script>

<style scoped>



.plug_service {
  border-radius: 10px;
  position: fixed;
  right: 1%;
  bottom: 2%;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.7);
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 68px;
}
.plug_service li a {
  cursor: pointer;
  display: block;
  text-align: center;
  padding: 2px 8px 6px 8px;
  font-size: 12px;
  color: #505050;
  outline: none;
}
.plug_service div {
  font-size: 10px;
}
.am-animation-slide-bottom {
  -webkit-animation-name: am-slide-bottom;
  animation-name: am-slide-bottom;
}
/* 隐藏内容容器 */
.content-container-gfwx {
  display: none;
  border-radius: 10px;
  position: absolute;
  left: 100%;
  top: 0;
  margin-left: -195px;
  padding: 10px;
  background-color: #f1f1f1;
  border: 1px solid #ececec;
}

/* 当鼠标悬停在列表项上时，显示内容容器 */
li:hover .content-container-gfwx {
  display: block;
}
/* 隐藏内容容器 */
.content-container-kfrx {
  display: none;
  border-radius: 10px;
  position: absolute;
  left: 100%;
  top: 0;
  margin-left: -220px;
  margin-top: 65px;
  padding: 10px;
  background-color: #f1f1f1;
  border: 1px solid #ececec;
}

/* 当鼠标悬停在列表项上时，显示内容容器 */
li:hover .content-container-kfrx {
  display: block;
}
</style>
