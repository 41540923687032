import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        websiteInfo: {},
        aboutInfo: {},
        navList: [],
        navId: 0
    },
    mutations: {
        updateWebsiteInfo(state, payload) {
            state.websiteInfo = payload;
        },
        updateAboutInfo(state, payload) {
            state.aboutInfo = payload;
        },
        updateNavList(state, payload) {
            state.navList = payload;
        },
        updateNavId(state, payload) {
            state.navId = payload;
        },
    },
    actions: {
        setWebsiteInfo({ commit }, payload) {
            commit('updateWebsiteInfo', payload);
        },
        setAboutInfo({ commit }, payload) {
            commit('updateAboutInfo', payload);
        },
        setNavList({ commit }, payload) {
            commit('updateNavList', payload);
        },
        setNavId({ commit }, payload) {
            commit('updateNavId', payload);
        },
    },
    getters: {
        getWebsiteInfo: (state) => state.websiteInfo,
        getAboutInfo: (state) => state.aboutInfo,
        getNavList: (state) => state.navList,
        getNavId: (state) => state.navId,
    },
});

export default store;
